import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { FaCheck } from '@react-icons/all-files/fa/FaCheck'

const Checklist = props => {
  const { children: originalChildren, ...rest } = props
  const children = Array.isArray(originalChildren) ? originalChildren : [originalChildren]
  return (
    <ChecklistUl {...rest}>
      {children.map((child, index) => (
        <li key={index}>
          <FaCheck aria-hidden='true' focusable='false' role='presentation' />
          <div>{child}</div>
        </li>
      ))}
    </ChecklistUl>
  )
}

Checklist.propTypes = {
  children: PropTypes.node.isRequired,
}

const ChecklistUl = styled.ul`
  list-style: none;

  li {
    display: flex;
    flex-direction: row;
    width: 100%;

    > svg {
      color: ${props => props.theme.color.g400};
      flex-shrink: 0;
      margin-right: ${props => props.theme.size.xs};
      margin-top: 2px;
    }

    > div {
      flex-grow: 2;
    }
  }
`

export default Checklist
